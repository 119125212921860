.marker {
    width: 54px;
    height: 54px;
    background-color: rgba(74, 161, 235, 0.15);
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid rgba(74, 161, 235, 0.65);
    transition: all .4s ease;
}

.markerIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    transition: transform .4s ease;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.markerIcon[data-violent-act="killing"] {
    background-color: var(--sw-color-killing);
}

.markerIcon[data-violent-act="abuse"] {
    background-color: var(--sw-color-abuse);
}

.markerIcon[data-violent-act="persecution"] {
    background-color: var(--sw-color-persecution);
}

.marker svg {
    height: 18px;
    width: 18px;
    fill: var(--sw-color-black);
}

.marker:hover {
    /*padding: 10px;*/
    transform: scale(1.2);
    /*width: calc(64px * 2);*/
    /*height: calc(64px * 2);*/
}

.marker:hover .markerIcon {
    /*transform: translate(-50%, -50%) scale(1.1);*/
}
