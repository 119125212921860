.spinnerContainer {
    display: flex;
    justify-content: center;
}
.spinner {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -0.125em;
    border-radius: 50%;
    animation: 0.75s linear infinite spinner-border;
    border: 0.25em solid var(--sw-color-white);
    border-right-color: transparent;
}

@keyframes spinner-border {
    100% {
        transform: rotate(360deg);
    }
}
