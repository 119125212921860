.landing {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(31, 31, 31, 0.80);
    backdrop-filter: blur(5px);
}

.landingLayout {
    max-width: 800px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 10px;
}
.landingLayout svg {
    width: 300px;
    fill: var(--sw-color-brand);
}

.landingContent {
    color: white;
    text-align: center;
}

.landingContent h1 {
    font-size: 40px;
}

.visible {
    opacity: 1;
    transition: all .4s ease-in-out;
}

.hidden {
    opacity: 0;
    visibility: hidden;
    transition: all .4s ease-in-out;
}
