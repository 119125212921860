.buttonBase {
    width: 26px;
    height: 26px;
    z-index: 10;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    transition: all .4s ease;
    padding: 0;
}

.buttonBase:hover {
    cursor: pointer;
    border-color: var(--color-base);
    filter: drop-shadow(4px 6px 6px black);
}

.buttonBase:hover svg {
    fill: var(--color-base);
}

.buttonBase svg {
    transition: all .4s ease;
    fill: white;
    width: 100%;
    height: 100%;
}
