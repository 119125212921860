.App {
    margin: 0;
    padding: 0;
    background-color: transparent;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    overflow: hidden;
}

::selection {
    background-color: var(--sw-color-white);
    color: var(--sw-color-black)
}

/*
MAPBOX OVERIDES
 */
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    /*border-top-color: var(--sw-color-bg-dark);*/
    /*border-color: var(--sw-color-border-base);*/
}

.mapboxgl-popup-close-button {
    border: 0;
    border-radius: 0 3px 0 0;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 15px;
    background: transparent;
    font-size: 36px;
    color: white;
}

.mapboxgl-popup-content {
    background-color: transparent;
    padding: 0;
    box-shadow: none;
}


.appear-bottom {
    animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

@keyframes slide-in-blurred-bottom {
    0% {
        transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
        transform-origin: 50% 100%;
        height: 0;
        /*filter: blur(40px);*/
        /*opacity: 0;*/
    }
    100% {
        transform: translateY(0) scaleY(1) scaleX(1);
        transform-origin: 50% 50%;
        /*filter: blur(0);*/
        /*opacity: 1;*/
        height: 100%;
    }
}

.leave-bottom {
    animation: slide-out-blurred-bottom 0.4s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
}

@keyframes slide-out-blurred-bottom {
    0% {
        transform: translateY(0) scaleY(1) scaleX(1);
        transform-origin: 50% 50%;
        /*filter: blur(0);*/
        /*opacity: 1;*/
    }
    100% {
        transform: translateY(1000px) scaleY(2) scaleX(0.2);
        transform-origin: 50% 100%;
        visibility: hidden;
        height: 0;
        /*filter: blur(40px);*/
        /*opacity: 0;*/
    }
}
