.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(20px);
    z-index: 999;
    opacity: 0;
}

.enter {
    transition: opacity .4s ease;
    opacity: 1;
}

.leave {
    transition: all .4s ease;
    opacity: 0;
    visibility: hidden;
}

.modalLayout {
    padding-top: 100px;
    padding-bottom: 100px;
    height: calc(100vh - 200px);
    overflow-y: auto;
}

.modalClose {
    position: fixed;
    top: 65px;
    right: 30px;
    z-index: 1050;
}

.modalClose button {
    background: transparent;
    padding: 10px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--sw-color-white);
    cursor: pointer;
}

.modalClose button svg {
    width: 23px;
    height: 23px;
}

.modalText {
    max-width: 820px;
    margin: 0 auto;
    color: var(--sw-color-white);
    font-family: monospace;

    h1 {
        font-size: 45px;
    }

    h2 {
        font-size: 33px;
    }

    p {
        margin-bottom: 30px;
    }
}
