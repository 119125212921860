.board {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 10px;
    gap:10px;
}
