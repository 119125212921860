.chartBoard {
    /*position: absolute;*/
    /*bottom: 15px;*/
    /*right: 100px;*/
    /*width: 300px;*/
    height: auto;
    z-index: 1;
    display: flex;
    /*background-color: var(--sw-color-border-base);*/
}

.chartBoard[data-state="visible"] {
    /*opacity: 1;*/
    animation: flip-in-hor-bottom .8s cubic-bezier(.38, 0, .11, .99) both;
}

.chartBoard[data-state="hidden"] {
    /*opacity: 0;*/
    animation: flip-out-hor-bottom 0.5s cubic-bezier(.38, 0, .11, .99) both;
}


@keyframes flip-in-hor-bottom {
    0% {
        transform: translateX(150%);
        /*opacity: 0;*/
    }
    100% {
        transform: translateX(0);
        /*opacity: 1;*/
    }
}

@keyframes flip-out-hor-bottom {
    0% {
        transform: translateX(0);
        /*opacity: 0;*/
    }
    100% {
        transform: translateX(150%);
        /*opacity: 1;*/
    }
}

