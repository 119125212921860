.buttonRound {
    width: 50px;
    height: 50px;
    /*position: absolute;*/
    /*right: 10px;*/
    /*bottom: 70px;*/
    z-index: 10;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(15px) brightness(.8);
    border: 1px solid rgba(155, 155, 155, 0.49);
    background-color: rgba(255, 255, 255, 0.04);
    transition: all .4s ease;
    margin: 5px 0;
}

.buttonRound[data-state="active"] {
    border-color: var(--sw-color-brand);
}
.buttonRound[data-state="active"] svg {
    fill: var(--sw-color-brand);
}

.buttonRound:hover {
    cursor: pointer;
    border-color: var(--color-base);
    filter: drop-shadow(4px 6px 6px black);
    background-color: var(--sw-color-brand);
}

.buttonRound:hover svg {
    fill: var(--sw-color-black);
}

.buttonRound svg {
    transition: all .4s ease;
    fill: white;
    width: 22px;
    height: 22px;
}
