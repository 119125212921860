.boardInfo {
    /*position: absolute;*/
    /*left: 50%;*/
    /*transform: translateX(-50%);*/
    z-index: 1;
    bottom: 15px;
    /*min-width: 680px;*/
    width: 50%;
}

.boardInfo[data-state="visible"] {
    /*opacity: 1;*/
    animation: entrance .8s cubic-bezier(.38,0,.11,.99) both;
}

.boardInfo[data-state="hidden"] {
    /*opacity: 0;*/
    animation: exit 0.5s cubic-bezier(.38,0,.11,.99) both;
}

@keyframes entrance {
    0% {
        transform: translateY(150%);
        /*opacity: 0;*/
    }
    100% {
        transform: translateY(0);
        /*opacity: 1;*/
    }
}

@keyframes exit {
    0% {
        transform: translateY(0);
        /*opacity: 0;*/
    }
    100% {
        transform: translateY(150%);
        /*opacity: 1;*/
    }
}
