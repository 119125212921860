@font-face {
    font-family: 'JetBrains Mono';
    src: url('./fonts/JetBrainsMono-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'JetBrains Mono';
    src: url('./fonts/JetBrainsMono-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
:root {
    --color-base: rgba(1, 253, 241, 0.9);
    --sw-color-abuse: #6e32e1;
    --sw-color-abuse-alpha: rgba(110, 50, 225, 0.81);
    --sw-color-killing: #0CF25D;
    --sw-color-killing-alpha: rgba(12, 242, 93, 0.81);
    --sw-color-persecution: #0dcaf0;
    --sw-color-persecution-alpha: rgba(13, 202, 240, 0.81);
    --sw-color-persecution_secondary: #F25CAF;
    --sw-color-border-white: rgba(252, 251, 251, 0.69);
    --sw-color-border-base: hsla(0, 0%, 100%, .1);
    --sw-color-bg-dark: rgba(39, 39, 42, .3);
    --sw-color-white: #ffffff;
    --sw-color-black: #232323;
    --sw-color-brand: rgba(1, 253, 207, 0.9);
    --sw-color-body: #1f1f1f;
    --font-mono: 'JetBrains Mono';
}
body {
    margin: 0;
    font-family: Helvetica, Arial, sans-serif;
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
    /*'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
    /*sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--sw-color-body);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

canvas:focus-visible {
    outline: none;
}
