.header {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    padding: 10px;
}

.headerNav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 80px;
}

.headerBrand {
    display: flex;
}

.headerBrand svg {
    width: 85px;
    height: auto;
    padding: 0 5px;
    fill: var(--sw-color-brand);
}

.headerMenu {
    display: flex;
    /*padding: 0 120px;*/
}

.headerMenu button {
    background-color: transparent;
    border: none;
    color: var(--sw-color-white);
    font-family: sans-serif;
    font-weight: bold;
    text-transform: capitalize;
    cursor: pointer;
    transition: opacity .3s ease;
}
.headerMenu button:hover {
    opacity: 0.7;
}

button.active {
    color: var(--color-base);
}
