.popup {
    width: 100%;
    min-height: 280px;
    border: 1px solid var(--sw-color-border-base);
    border-radius: 10px;
    background-color: var(--sw-color-bg-dark);
    backdrop-filter: blur(15px) brightness(.5);
    animation: slide-in-blurred-top 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
    color: var(--sw-color-white);
    height: 100%;
}

.popupContent {
    padding: 20px;
}

.popupContentRow {
    margin: 20px 0;
    display: flex;
    width: 100%;
}

.popupContentRow > div {
    flex: 1 1 auto;
}

.popupContentInformation section {
    max-height: 240px;
    overflow-y: auto;
    padding: 0 10px;
    font-family: monospace;
    font-size: 10px;
}

.popupContentInformation section::-webkit-scrollbar {
    /*display: none;*/
    width: 8px;
}


/* Track */
.popupContentInformation section::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
.popupContentInformation section::-webkit-scrollbar-thumb {
    background: var(--sw-color-white);
    border-radius: 10px;
    border: 1px solid var(--sw-color-border-base);
}

/* Handle on hover */
.popupContentInformation section::-webkit-scrollbar-thumb:hover {
    background: var(--sw-color-brand);
    cursor: pointer;
}

.popupHeader {
    display: flex;
    align-items: center;
    padding: 0 10px 20px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--sw-color-border-base);
}

.popupHeader svg {
    width: auto;
    height: 33px;
    display: flex;
}

.popupHeader h2 {
    margin: 0;
    padding: 0 10px;
    font-size: 2em;
}

.popupSourcesContainer {
    width: 100%;
}

.popupSourcesList {
    margin-top: -20px;
    padding: 0 10px;
    color: var(--sw-color-white);
    /*width: 100%;*/
    /*display: flex;*/
    /*flex-direction: column;*/
}

@keyframes slide-in-blurred-top {
    0% {
        transform: translateY(-100px) scaleY(.1) scaleX(0.1);
        transform-origin: 50% 50%;
        filter: blur(40px) brightness(1);
    }
    100% {
        transform: translateY(0) scaleY(1) scaleX(1);
        transform-origin: 50% 50%;
        filter: blur(0) brightness(1);
    }
}
