.countryDataContainer {
    width: 100%;
}

.countryDataGrid {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 5px 0;
}

.countryDataItem {
    padding: 0 10px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex: 0 0 calc(25% - 20px);
    font-size: 10px;
}

.countryDataItemLabel {
    font-weight: bold;
    color: white;
    font-family: monospace;
    margin-bottom: 5px;
    margin-top: 0;
}

.countryDataItemValue {
    font-weight: normal;
    color: whitesmoke;
    font-family: monospace;
    word-break: break-all;
    line-height: 1.3em;
}
