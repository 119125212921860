.pillViolentAct {
    display: inline-flex;
    padding: 2px 10px;
    border-radius: 20px;
    border: 1px solid var(--sw-color-border-white);
    text-transform: uppercase;
    font-weight: bold;
}

.pillViolentAct[data-pill='killing'] {
    background-color: var(--sw-color-killing);
    color: var(--sw-color-black);
}

.pillViolentAct[data-pill='abuse'] {
    background-color: var(--sw-color-abuse);
    color: var(--sw-color-white);
}

.pillViolentAct[data-pill='persecution'] {
    background-color: var(--sw-color-persecution);
    color: var(--sw-color-white);
}


