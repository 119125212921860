.link {
    display: flex;
    align-items: center;
}

.link span:first-child {
    padding-right: 5px;
}

.link a {
    display: flex;
    color: var(--sw-color-white);
    text-decoration: none;
    font-family: monospace;
}

.link a:hover {
    color: var(--sw-color-brand);
}
