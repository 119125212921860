.mapData {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mapCoordinates {
    display: flex;
    align-items: center;
    flex: 1
    /*flex-direction: column;*/
}

.mapDataButton {
    width: 30px;
    margin-left: auto;
}
.mapCoordinates div {
    flex: 0 0 15%;
    /*flex-direction: row;*/
}
.mapCoordinates div:first-child {
    flex: 0 0 40%;
}
