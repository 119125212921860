.appButton {
    display: inline-block;
    padding: 8px 16px;
    border-radius: 15px;
    border: 1px solid var(--sw-color-brand);
    cursor: pointer;
    transition: all .4s ease;
    color: var(--sw-color-brand);
    background-color: black;
    font-size: 14px;
}

.appButton:hover {
    background-color: var(--sw-color-brand);
    color: black;
    border-color: var(--sw-color-brand);
}
