.configBoard {
    /*position: absolute;*/
    /*right: 10px;*/
    /*bottom: 10px;*/
    /*z-index: 2;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.configBoard button:last-child {
    margin-bottom: 0;
}

.configBoard button:first-child {
    margin-top: 0;
}
